import Swal from 'sweetalert2'
export default {
    methods: {
        ShowAlertSuccess(msg, htmlmsg) {
            Swal.fire({
                position: "top-end",
                title: "Success!",
                text: msg,
                icon: "success",
                html: `${htmlmsg}`,
               
                focusConfirm: false,
                confirmButtonText: `
                Okay <i class="fa fa-arrow-right pl-2"></i> 
                `,
                confirmButtonAriaLabel: "Thumbs up, close!",
                confirmButtonColor:'Green',
                showClass: {
                    popup: `
                      animate__animated
                      animate__fadeInUp
                      animate__faster
                    `
                },
                hideClass: {
                    popup: `
                      animate__animated
                      animate__fadeOutDown
                      animate__faster
                    `
                }
            });
        },
        ShowAlertWarning(msg, htmlmsg) {
            Swal.fire({
                position: "top-end",
                title: "hey!",
                text: msg,
                icon: "warning",
                html: `${htmlmsg}`,              
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: `
                Okay <i class="fa fa-arrow-right pl-2"></i> 
                `,
                confirmButtonAriaLabel: "Thumbs down, close!",
                confirmButtonColor:'Black',
                showClass: {
                    popup: `
                      animate__animated
                      animate__fadeInUp
                      animate__faster
                    `
                },
                hideClass: {
                    popup: `
                      animate__animated
                      animate__fadeOutDown
                      animate__faster
                    `
                }
            });
        },
        ShowAlertError(msg, htmlmsg) {
            Swal.fire({
                position: "top-end",
                title: "Oops!",
                text: msg,
                icon: "error",
                html: `${htmlmsg}`,                
                focusConfirm: false,
                confirmButtonText: `
                Okay <i class="fa fa-arrow-right pl-2"></i> 
                `,
                confirmButtonAriaLabel: "Thumbs down, close!",
                confirmButtonColor:'Red',
                showClass: {
                    popup: `
                      animate__animated
                      animate__fadeInUp
                      animate__faster
                    `
                },
                hideClass: {
                    popup: `
                      animate__animated
                      animate__fadeOutDown
                      animate__faster
                    `
                }
            });
        },
        ShowAlertInfo(msg, htmlmsg) {
            Swal.fire({
                position: "top-end",
                title: "Hi!",
                text: msg,
                icon: "info",
                html: `${htmlmsg}`,
              
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: `
                Okay <i class="fa fa-arrow-right pl-2"></i> 
                `,
                confirmButtonAriaLabel: "Thumbs down, close!",
                confirmButtonColor:'Red',
                showClass: {
                    popup: `
                      animate__animated
                      animate__fadeInUp
                      animate__faster
                    `
                },
                hideClass: {
                    popup: `
                      animate__animated
                      animate__fadeOutDown
                      animate__faster
                    `
                }
            });
        },       
    },
}