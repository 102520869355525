<template>
    <div>
        <div v-if="ReportItems">
            <table class="table table-dark table-bordered table-striped table-hover">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Status</th>
                        <th>Created By</th>
                        <th>Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in searchedResultQuery" :key="item.Id">

                        <td>{{ item.GeneratedReportFileTitle }}</td>
                        <td>{{ item.GeneratedReportFileStatus }}</td>
                        <td>{{ item.CreatedByUserName }}</td>
                        <td>{{frontEndDateFormat(item.DateCreated) }}</td>
                        <td>
                            <button class="btn btn-secondary btn-sm"
                                v-on:click="downloadreport(item.GeneratedReportFilePath)">
                                <i class="fa fa-file-excel"></i> Download
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
  
<script>
import moment from "moment";
import swal from "sweetalert";

export default {
    name: "Report",
    data() {
        return {
            ReportItems: null,
            onloader: "none",
        };
    },

    methods: {
        getcurendate() {
            var today = new Date();
            this.currdate =
                today.getDate() +
                "/" +
                (today.getMonth() + 1) +
                "/" +
                today.getFullYear();
        },
        GetReportGenerated() {
            this.onloader = "flex";
            this.$store
                .dispatch("GetReportGenerated")
                .then((resp) => {
                    this.ReportItems = resp.data;
                    this.onloader = "none";
                })
                .catch((err) => {
                    console.log(err);
                    this.onloader = "none";
                    this.$store.dispatch("logout");
                    this.$router.push("/login");
                });
        },
        downloadreport(filePath) {
            if (filePath != null) {              
                window.open(this.$store.state.auth.baseurl + filePath, "_blank");
            }
        },
        frontEndDateFormat(date) {
            return moment(date).format("DD-MM-YYYY");
        },
        backEndDateFormat(date) {
            return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
        },
        checkresponse(resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch("logout");
                this.$router.push("/login");
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        },
    },
    computed: {
        searchedResultQuery() {
            if (this.searchval) {
                return this.ReportItems.filter(item => {
                    return this.searchval
                        .toLowerCase()
                        .split(" ")
                        .every(
                            v =>
                                item.generatedReportFileTitle.toLowerCase().includes(v) ||
                                item.createdByUserName.toLowerCase().includes(v)
                        );
                });
            } else {
                return this.ReportItems;
            }
        }
    },
    mounted() {
        this.GetReportGenerated();
    },
};
</script>
  
<style>
.borderall {
    text-align: center;
    border: 3px solid #000;
}

.bordercol {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    border: thin solid #000;
}

.padbody {
    padding: 20px;
}

.padhead {
    padding: 5px;
    border: 3px solid #606060;
}

.pad {
    padding: 10px;
}

table {
    font-size: 9pt;
    width: 100%;
}

.bodytext {
    font-size: 9pt;
}
</style>
  